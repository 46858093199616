<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <add-vendor-user-form :closeSidebar="closeSidebar" :reloadParent="load" />
    </b-sidebar>
    <div class="card">
      <div
        class="card-header d-flex flex-column align-items-start justify-content-start"
      >
        <b-button @click="handleNewVendorClick" class="mb-75" variant="success"
          ><feather-icon class="mr-75" icon="PlusIcon" />New Vendor</b-button
        >
      </div>
      <div class="card-body">
        <table role="table" class="table table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">Vendor</th>
              <th role="columnheader" scope="col">Total Users</th>
              <th role="columnheader" scope="col">Created At</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="vendor in vendorList"
              :key="vendor._id"
              role="row"
              class="cursor-pointer"
              @click="handleVendorClick(vendor._id)"
            >
              <td aria-colindex="2" role="cell" style="width: 200px">
                <div class="d-flex justify-content-start" style="width: 200px">
                  <p class="font-weight-bolder mb-0 text-truncate">
                    {{ vendor.name }}
                  </p>
                </div>
              </td>

              <td aria-colindex="2" role="cell">
                <div class="d-flex justify-content-start">
                  <b-avatar variant="light-primary">
                    {{ vendor.users.length }}
                  </b-avatar>
                  <!-- <p class="text-left text-primary font-weight-bold mb-0">
                    {{ vendor.users.length }}
                  </p> -->
                </div>
              </td>
              <td aria-colindex="2" role="cell">
                <div
                  class="d-flex justify-content-start"
                  v-if="vendor.created_at && vendor.created_at !== null"
                >
                  <p class="text-center font-weight-bold mb-0">
                    {{ vendor.created_at | moment }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex align-items-center justify-content-center w-100 py-2"
        >
          <b-pagination
            v-model="filters.page"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BOverlay,
  BSidebar,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import AddVendorUserForm from "./components/AddVendorUserForm.vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import TaskMixins from "../../mixins/TaskMixins";
import moment from "moment";
export default {
  components: {
    BButton,
    BSidebar,
    BOverlay,
    AddVendorUserForm,
    BPagination,
    BAvatar,
  },
  mixins: [ResponseMixins, ThirdPartyRisksMixins, TaskMixins],
  data() {
    return {
      showOverlay: false,
      vendorList: null,
      pagination: {
        lastPage: 1,
        totalRows: 1,
        perPage: 10,
      },
      filters: {
        page: 1,
        limit: 10,
      },
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  watch: {
    filters: {
      handler: function (newValue) {
        this.getVendorsAndSetData(newValue);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isUserVendor()) {
      console.log("VendorId", this.$store.state.app.user.vendor_id);
      this.$router.push(
        `/thirdpartyrisks/${this.$store.state.app.user.vendor_id}/details`
      );
    } else {
      this.load();
    }
  },

  methods: {
    load() {
      this.getVendorsAndSetData(this.filters);
    },

    isUserVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      } else {
        return false;
      }
    },

    handleVendorClick(id) {
      this.$router.push(`/thirdpartyrisks/${id}/details`);
    },

    getVendorsAndSetData(params) {
      this.showOverlay = true;
      this.getVendors(params)
        .then((res) => {
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.totalRows = res.data.data.total;
          this.filters.page = res.data.data.current_page;
          this.filters.limit = res.data.data.per_page;
          this.vendorList = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewVendorClick() {
      this.openSidebar();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
  },
};
</script>


<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>