<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
    class="h-100"
  >
    <b-modal ref="my-modal" hide-footer title="New Vendor Category" size="lg">
      <validation-observer ref="UploadForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewCategorySubmit">
          <b-form-group label="Name">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input v-model="newCategory.title" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Description">
            <validation-provider #default="{ errors }" name="Description">
              <b-form-textarea
                id="textarea"
                v-model="newCategory.description"
                rows="3"
                max-rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            class="mt-3 mr-1"
            :disabled="invalid"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <div class="card h-auto">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 70%"
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <h2 class="font-weight-bolder mb-75">
            <span class="align-middle">Create a new vendor</span>
          </h2>
          <p class="card-subtitle text-muted">
            Fill the form below and click submit to create a new vendor.
          </p>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body pt-3 h-100">
        <validation-observer ref="add_vendor_form" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleSubmitClick()">
            <b-form-group label="Vendor Name">
              <template slot="label">
                Vendor Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="vendor"
                name="Vendor"
                :rules="{
                  required: true,
                  min: 3,
                }"
              >
                <b-form-input
                  v-model="formData.vendor"
                  id="vendor"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <div class="w-100 my-2 d-flex align-items-center justify-content-center">
                <p class="mb-0">--User--</p>
            </div> -->
            <!-- First Name & Last Name -->
            <div class="d-flex align-items-center justify-content-between">
              <b-form-group label="First Name" style="width: 47%">
                <template slot="label">
                  First Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  vid="first_name"
                  name="First Name"
                  :rules="{
                    required: true,
                    min: 3,
                  }"
                >
                  <b-form-input
                    v-model="formData.firstName"
                    id="first_name"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Last Name" style="width: 47%">
                <template slot="label">
                  Last Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  vid="last_name"
                  name="Last Name"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-input
                    v-model="formData.lastName"
                    id="last_name"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <!-- <b-form-group label="Category" class="w-100">
              <validation-provider
                #default="{ errors }"
                vid="category"
                name="Category"
                :rules="{
                  required: false,
                }"
              >
                <b-form-input
                  v-model="formData.category"
                  id="category"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <div class="d-flex align-items-start justify-content-between">
              <b-form-group label="Category" class="w-75">
                <template slot="label">
                  Category <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  :rules="{
                    required: true,
                  }"
                >
                  <vue-autosuggest
                    :suggestions="formData.category.suggestions"
                    :limit="5"
                    v-model="formData.category.selectedCategoryName"
                    id="autosuggest__input"
                    :input-props="formData.category.inputProps"
                    @input="getCategoriesBySearchPhraseAndSetOptions"
                    @selected="onCategorySelected"
                    :get-suggestion-value="getCategorySuggestionValue"
                  >
                    <template
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      <span style="{ display: 'flex', color: 'navyblue'}"
                        >{{ suggestion.item.title }}
                      </span>
                    </template>
                  </vue-autosuggest>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div
                  class="d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"
                >
                  <b-badge
                    class="mr-25 mt-25"
                    v-for="category in formData.category.selectedCategories"
                    variant="light-primary"
                    :key="category._id"
                    >{{ category.title
                    }}<feather-icon
                      @click="handleRemoveCategory(category._id)"
                      class="text-danger ml-25 cursor-pointer"
                      icon="XIcon"
                  /></b-badge>
                </div>
              </b-form-group>

              <div
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <b-button
                  @click="handleNewCategoryClick"
                  variant="success"
                  style="margin-top: 26px"
                  ><feather-icon icon="PlusIcon" class="mr-50" />Add
                  Category</b-button
                >
              </div>
            </div>

            <b-form-group label="Tags" class="w-100">
              <vue-autosuggest
                :suggestions="tagOptions"
                :limit="5"
                v-model="tagName"
                id="autosuggest__input"
                :input-props="tagInputProps"
                @input="getAssetTagsBySearchPhraseAndSetData"
                @selected="onTagSelected"
                :get-suggestion-value="getTagSuggestionValue"
              >
                <template
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <span style="{ display: 'flex', color: 'navyblue'}">{{
                    suggestion.item.title
                  }}</span>
                </template>
              </vue-autosuggest>
              <div
                class="d-flex flex-wrap align-items-center justify-content-start mt-75"
              >
                <template v-for="(value, i) in formData.selectedTags">
                  <b-badge :key="i" variant="light-primary" class="mr-1">
                    <span>{{ value.tag_title }}</span>
                    <feather-icon
                      @click="removeTag(value.tag_title)"
                      icon="XCircleIcon"
                      class="cursor-pointer ml-50 text-danger"
                    />
                  </b-badge>
                </template>
              </div>
            </b-form-group>

            <b-form-group label="Email" class="w-100">
              <template slot="label">
                Email <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="user_id"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="formData.email"
                  id="email"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Mobile" class="w-100">
              <template slot="label">
                Mobile <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="mobile"
                name="Mobile"
                rules="required|digits:10"
              >
                <b-form-input
                  v-model="formData.mobile"
                  id="mobile"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mt-2">
              <validation-provider
                #default="{ errors }"
                vid="should_invite"
                name="Invitation Email"
              >
                <b-form-checkbox
                  id="should_invite"
                  v-model="formData.shouldInvite"
                  :value="true"
                  :unchecked-value="false"
                >
                  Send invitation email to user
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                type="submit"
                variant="primary"
                class="mt-1 w-25"
                :disabled="invalid"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Create</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BFormSelect,
  BBadge,
  BModal,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import UtilsMixins from "../../../mixins/UtilsMixins";
// import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import VendorMixins from "../../../mixins/VendorMixins";
import TagMixins from "../../../mixins/TagMixins";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BBadge,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    BFormSelect,
    VueAutosuggest,
    BModal,
    BFormTextarea,
  },
  data() {
    return {
      showOverlay: false,
      newCategory: {
        title: null,
        description: null,
      },
      formData: {
        firstName: null,
        lastName: null,
        category: {
          suggestions: [],
          selectedCategory: null,
          selectedCategoryName: null,
          selectedCategories: [],
          inputProps: {
            class: "form-control",
            placeholder: "Search Categories..",
          },
        },
        email: null,
        mobile: null,
        selectedTags: [],
        vendor: null,
        shouldInvite: false,
      },

      tagOptions: [],
      tagName: "",
      tagInputProps: {
        class: "form-control",
        placeholder: "Search & Select Tags..",
      },
    };
  },
  mixins: [
    UtilsMixins,
    ResponseMixins,
    ThirdPartyRisksRAMixins,
    VendorMixins,
    TagMixins,
  ],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleSubmitClick() {
      this.showOverlay = true;
      this.addNewVendor(this.formData)
        .then((res) => {
          this.reloadParent();
          this.handleResponse(res);
          this.closeSidebar();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
          if (err.response.status === 422) {
            this.$refs.add_vendor_form.setErrors(err.response.data.errors);
          }
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getAssetTagsBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        this.debounceFunction(() => {
          this.getAssetTagsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.tagOptions = [];
              const newTags = res.data.data.data;
              if (newTags.length == 0) {
                newTags.push({ title: searchPhrase, is_new: true });
              }
              this.tagOptions.push({ name: "tags", data: newTags });
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      } else {
        this.tagOptions = [];
      }
    },
    onTagSelected(val) {
      if (val) {
        const selectedVal = val.item;
        const tagIndex = this.formData.selectedTags.findIndex(
          (tag) => tag.tag_title === selectedVal.title
        );
        if (tagIndex === -1) {
          this.tagName = "";
          this.formData.selectedTags.push({
            tag_id: selectedVal._id,
            tag_title: selectedVal.title,
            is_new: selectedVal._id ? false : true,
          });
        }
      }
    },

    removeTag(tagName) {
      let newSelectedTags = this.formData.selectedTags.filter(
        (tag) => tag.tag_title !== tagName
      );
      this.formData.selectedTags = newSelectedTags;
    },

    getTagSuggestionValue(suggestion) {
      return suggestion.item.title;
    },

    handleRemoveCategory(categoryId) {
      let newCategories = this.formData.category.selectedCategories.filter(
        (category) => category._id !== categoryId
      );
      this.formData.category.selectedCategories = newCategories;
    },

    getCategoriesBySearchPhraseAndSetOptions(params) {
      console.log(params);
      this.debounceFunction(() => {
        this.getAssessmentCategories(params)
          .then((res) => {
            this.formData.category.suggestions.push({
              name: "categories",
              data: res.data.data.data,
            });
          })
          .catch((err) => {
            this.handleError(err);
          });
      });
    },

    onCategorySelected(value) {
      this.formData.category.selectedCategories.push(value.item);
      this.formData.category.selectedCategoryName = value.item.title;
    },

    getCategorySuggestionValue(suggestion) {
      return suggestion.item.name;
    },

    handleNewCategoryClick() {
      this.newCategory = {
        title: null,
        description: null,
      };
      this.showModal();
    },
    handleNewCategorySubmit() {
      this.showOverlay = true;
      this.createNewVendorCategory(this.newCategory)
        .then((res) => {
          this.hideModal();
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
  },
};
</script>

<style>
</style>