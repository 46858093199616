var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"h-100",attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('b-modal',{ref:"my-modal",attrs:{"hide-footer":"","title":"New Vendor Category","size":"lg"}},[_c('validation-observer',{ref:"UploadForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleNewCategorySubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('template',{slot:"label"},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.newCategory.title),callback:function ($$v) {_vm.$set(_vm.newCategory, "title", $$v)},expression:"newCategory.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea","rows":"3","max-rows":"6"},model:{value:(_vm.newCategory.description),callback:function ($$v) {_vm.$set(_vm.newCategory, "description", $$v)},expression:"newCategory.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-3 mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_vm._v(" Submit ")],1)],1)]}}])})],1),_c('div',{staticClass:"card h-auto"},[_c('div',{staticClass:"card-header border-bottom d-flex"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-start",staticStyle:{"width":"70%"}},[_c('h2',{staticClass:"font-weight-bolder mb-75"},[_c('span',{staticClass:"align-middle"},[_vm._v("Create a new vendor")])]),_c('p',{staticClass:"card-subtitle text-muted"},[_vm._v(" Fill the form below and click submit to create a new vendor. ")])]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)]),_c('div',{staticClass:"card-body pt-3 h-100"},[_c('validation-observer',{ref:"add_vendor_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick()}}},[_c('b-form-group',{attrs:{"label":"Vendor Name"}},[_c('template',{slot:"label"},[_vm._v(" Vendor Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"vendor","name":"Vendor","rules":{
                required: true,
                min: 3,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vendor","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.vendor),callback:function ($$v) {_vm.$set(_vm.formData, "vendor", $$v)},expression:"formData.vendor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-form-group',{staticStyle:{"width":"47%"},attrs:{"label":"First Name"}},[_c('template',{slot:"label"},[_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"first_name","name":"First Name","rules":{
                  required: true,
                  min: 3,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticStyle:{"width":"47%"},attrs:{"label":"Last Name"}},[_c('template',{slot:"label"},[_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"last_name","name":"Last Name","rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('b-form-group',{staticClass:"w-75",attrs:{"label":"Category"}},[_c('template',{slot:"label"},[_vm._v(" Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Category","rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.formData.category.suggestions,"limit":5,"id":"autosuggest__input","input-props":_vm.formData.category.inputProps,"get-suggestion-value":_vm.getCategorySuggestionValue},on:{"input":_vm.getCategoriesBySearchPhraseAndSetOptions,"selected":_vm.onCategorySelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.title)+" ")])]}}],null,true),model:{value:(_vm.formData.category.selectedCategoryName),callback:function ($$v) {_vm.$set(_vm.formData.category, "selectedCategoryName", $$v)},expression:"formData.category.selectedCategoryName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"},_vm._l((_vm.formData.category.selectedCategories),function(category){return _c('b-badge',{key:category._id,staticClass:"mr-25 mt-25",attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(category.title)),_c('feather-icon',{staticClass:"text-danger ml-25 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.handleRemoveCategory(category._id)}}})],1)}),1)],2),_c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center"},[_c('b-button',{staticStyle:{"margin-top":"26px"},attrs:{"variant":"success"},on:{"click":_vm.handleNewCategoryClick}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_vm._v("Add Category")],1)],1)],1),_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Tags"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.tagOptions,"limit":5,"id":"autosuggest__input","input-props":_vm.tagInputProps,"get-suggestion-value":_vm.getTagSuggestionValue},on:{"input":_vm.getAssetTagsBySearchPhraseAndSetData,"selected":_vm.onTagSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.title))])]}}],null,true),model:{value:(_vm.tagName),callback:function ($$v) {_vm.tagName=$$v},expression:"tagName"}}),_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-start mt-75"},[_vm._l((_vm.formData.selectedTags),function(value,i){return [_c('b-badge',{key:i,staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_c('span',[_vm._v(_vm._s(value.tag_title))]),_c('feather-icon',{staticClass:"cursor-pointer ml-50 text-danger",attrs:{"icon":"XCircleIcon"},on:{"click":function($event){return _vm.removeTag(value.tag_title)}}})],1)]})],2)],1),_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Email"}},[_c('template',{slot:"label"},[_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"user_id","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Mobile"}},[_c('template',{slot:"label"},[_vm._v(" Mobile "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"mobile","name":"Mobile","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"vid":"should_invite","name":"Invitation Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"should_invite","value":true,"unchecked-value":false},model:{value:(_vm.formData.shouldInvite),callback:function ($$v) {_vm.$set(_vm.formData, "shouldInvite", $$v)},expression:"formData.shouldInvite"}},[_vm._v(" Send invitation email to user ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('b-button',{staticClass:"mt-1 w-25",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Create")])],1)],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }